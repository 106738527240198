.root {
	background-color: #fafafa;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: "hackman";
	src: local("hackman"), url("./fonts/hackman-bold.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "soho";
	src: local("soho"), url("./fonts/soho-gothic-pro.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "soho-light";
	src: local("soho-light"), url("./fonts/soho-gothic-pro-light.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "ample-soft-pro-regular";
	src: local("ample-soft-pro-regular"), url("./fonts/ample-soft-pro-regular.ttf") format("truetype");
	font-weight: normal;
}

@font-face {
	font-family: "ample-soft-pro-medium";
	src: local("ample-soft-pro-medium"), url("./fonts/ample-soft-pro-medium.ttf") format("truetype");
	font-weight: normal;
}